<template>
    <div class="register-success-tbf">
        <div class="success-modal">
            <div class="content-modal">
                <div class="content-icon forgot">
                    <img src="/build/flappy/bird_forgot.png" class="forgot"/>
                </div>
                <div class="title">{{ $t('forgot_password_success.title') }}</div>
                <div class="container-content-modal">
                    <div class="container-message">
                        {{ $t('forgot_password_success.description_1') }} <strong>{{ emailSend }}</strong> {{ $t('forgot_password_success.description_2') }}
                    </div>
                </div>
                <div class="action-form">
                    <button class="btn-tbf blue center" @click="redirect()">
                        <div class="text">{{ $t('forgot_password_success.button-action') }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
	data(){
		return {
            emailSend: ''
		}
	},
    mounted(){
        if(this.$route.query.email){
            this.emailSend = this.$route.query.email
        }else{
            this.emailSend = ''
        }
    },
	methods: {
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>